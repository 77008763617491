import React, { useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeSquare,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Card, CardBody } from "reactstrap";
// import ContactForm from "../components/ContactForm";
import SEO from "../components/SEO";
import TitleBar from "../components/Layout/TitleBar";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
// import { Helmet } from "react-helmet";

const IndexPage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
      <SEO
        title="Contact"
      />

      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="Contact Us" />
            <BreadCrumbs page="Contact" />
            <Card className="mb-3">
              <CardBody>
                <h3 className="mb-1">2175 Danforth Avenue,</h3>
                <h3 className="mb-3">Toronto, ON M4C1K4</h3>
                <a
                  className="contact-icon contact-link"
                  href="mailto:info@canpsylaw.ca"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faEnvelopeSquare}
                    size="2x"
                    className="mr-2"
                  />
                  <h4 className="mt-0">info@canpsylaw.ca</h4>
                </a>
                <div className="contact-icon">
                  <FontAwesomeIcon
                    icon={faPhoneSquare}
                    size="2x"
                    className="mr-2"
                  />
                  <h4 className="mt-0">416-499-7945</h4>
                </div>
              </CardBody>
            </Card>
            {/*<ContactForm />*/}
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default IndexPage;
